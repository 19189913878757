"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const Container = _styledComponents.default.div`
  ${({
  fluid = false
}) => fluid ? '' : 'max-width: 95vw;'}
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  &::after {
    content: '';
    display: table;
    clear: both;
  }
  @media (min-width: 1200px) {
    ${({
  fluid = false
}) => fluid ? '' : 'max-width: 1140px;'}
  }
`;
var _default = exports.default = Container;