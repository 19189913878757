"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatDateTime;
// Formats datetime string for display

// TODO: Consider BST

const occasion = require('occasion');
function formatDateTime(value, dateFormat) {
  if (!value) return '';
  const datePart = value.split('T')[0];
  const timePart = value.split('T')[1].split('.')[0];
  return occasion(datePart, dateFormat || 'D MMM') + ' ' + timePart.substring(0, 5);
}