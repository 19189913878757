"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getWebText;
function getWebText(application) {
  if (!application.onlineEntriesAvailable || !application.entriesSite) {
    if (application.infoSite) return ['Info: ' + application.infoSite.toLowerCase()];
    return [];
  }
  const webText = [];
  if (!application.infoSite) {
    webText.push('Online Entry: ' + application.entriesSite.toLowerCase());
  } else if (application.entriesSite.toLowerCase() === application.infoSite.toLowerCase()) {
    webText.push('Online Entry & Info: ' + application.entriesSite.toLowerCase());
  } else {
    webText.push('Online Entry: ' + application.entriesSite.toLowerCase());
    webText.push('Info: ' + application.infoSite.toLowerCase());
  }
  return webText;
}