"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getTitleText;
var _getSpecialTitleText = _interopRequireDefault(require("./getSpecialTitleText"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getTitleText(application) {
  const titleText = [];
  if (application.competitionTitle) titleText.push(application.competitionTitle.toUpperCase());
  const specialTitleText = (0, _getSpecialTitleText.default)(application.applicationSpecials);
  if (specialTitleText && specialTitleText.length) titleText.push(specialTitleText.toUpperCase());
  return titleText;
}