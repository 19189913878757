"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDates = getDates;
function getDates(startDateString, numberOfDays) {
  const startDate = new Date(startDateString);
  const dateArray = [];
  for (let i = 0; i < numberOfDays; i++) {
    dateArray.push(new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate() + i)).toISOString().split('T')[0]);
  }
  return dateArray;
}