"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sanitizeHtml = _interopRequireDefault(require("sanitize-html"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const sanitizerOptions = {
  transformTags: {
    // e.g.
    // 'h1': 'h2',
  },
  allowedTags: ['h1', 'h2', 'h3',
  // 'h4',
  // 'h5',
  // 'h6',
  // 'blockquote',
  'p', 'a', 'ul', 'ol',
  // 'nl',
  'li',
  // 'b',
  // 'i',
  'strong', 'em', 'del', 'ins',
  // 'strike',
  // 'code',
  // 'hr',
  'br'
  // 'div',
  // 'caption',
  // 'pre',
  // 'span',
  // 'img',
  ],
  allowedAttributes: {
    a: ['href', 'name'],
    img: ['src', 'align', 'alt']
  },
  allowedClasses: {
    // e.g.
    // code: ['lang-js'],
  },
  // URL schemes we permit
  allowedSchemes: ['http', 'https', 'mailto']
};
function sanitize(value) {
  if (typeof value !== 'string') {
    throw new Error('Expected RichText string but got a ' + typeof value);
  }
  return (0, _sanitizeHtml.default)(value, sanitizerOptions);
}
var _default = exports.default = sanitize;