"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/* global Raven */

function captureBreadcrumb(breadcrumb) {
  if (typeof Raven !== 'undefined') {
    Raven.captureBreadcrumb(breadcrumb);
  }
}
var _default = exports.default = captureBreadcrumb;