"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateTimeField = dateTimeField;
exports.dateTimeFromDatabase = dateTimeFromDatabase;
exports.dateTimeFromDate = dateTimeFromDate;
exports.dateTimeToDatabase = dateTimeToDatabase;
exports.default = void 0;
function dateTimeFromDatabase(value) {
  if (typeof value !== 'string') {
    throw new Error('Expected DateTime but got a ' + typeof value);
  }
  if (!/^\d\d\d\d\-\d\d\-\d\d \d\d\:\d\d\:\d\d$/.test(value)) {
    throw new Error('Expected DateTime to be in the form "YYYY-MM-DD hh:mm:ss" but got "' + value + '"');
  }
  return value.split(' ')[0] + 'T' + value.split(' ')[1] + '.000Z';
}
function dateTimeFromDate(value) {
  if (typeof value !== 'string') {
    throw new Error('Expected Date but got a ' + typeof value);
  }
  if (!/^\d\d\d\d\-\d\d\-\d\d$/.test(value)) {
    throw new Error('Expected Date to be in the form "YYYY-MM-DD" but got "' + value + '"');
  }
  return value + 'T00:00:00.000Z';
}
function dateTimeToDatabase(value) {
  if (typeof value !== 'string') {
    throw new Error('Expected DateTime but got a ' + typeof value);
  }
  if (!/^\d\d\d\d\-\d\d\-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ$/.test(value)) {
    throw new Error('Expected DateTime to be in the form "YYYY-MM-DDThh:mm:ss.000Z" but got "' + value + '"');
  }
  return value.split('T')[0] + ' ' + value.split('T')[1].split('.')[0];
}
function dateTimeField({
  isNullable
} = {}) {
  return {
    type: 'DateTime' + (isNullable ? '?' : ''),
    resolve(obj, args, context, subQuery, {
      fieldName
    }) {
      if (obj[fieldName] == null) {
        return null;
      }
      return dateTimeFromDatabase(obj[fieldName]);
    }
  };
}
var _default = exports.default = {
  name: 'DateTime',
  baseType: 'string',
  validate(value) {
    // expect value in the form YYYY-MM-DDThh:mm:ss.000Z
    return /^\d\d\d\d\-\d\d\-\d\dT\d\d\:\d\d\:\d\d\.\d\d\dZ$/.test(value);
  }
};