"use strict";

// add `Promise.done` and add Promise support for older browsers
if (typeof window !== 'undefined') {
  if (typeof window.Promise === 'undefined') {
    window.Promise = require('promise');
  } else {
    window.Promise.prototype.done = done;
  }
}
Promise.prototype.done = done;
function done(cb, eb) {
  return this.then(cb, eb).catch(ex => {
    setTimeout(() => {
      throw ex;
    }, 0);
  });
}