"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getSpecialTitleText;
function getSpecialTitleText(applicationSpecials) {
  const specials = applicationSpecials.filter(spec => spec.useInSubtitle);
  if (!specials.length) return '';
  if (specials.every(spec => spec.specialLabel === specials[0].specialLabel)) {
    return specials[0].specialLabel;
  }
  return specials.map(spec => spec.specialLabel).join(' & ');
}