"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userCanUpdateDivisionField = userCanUpdateDivisionField;
var _userIsCompAdmin = _interopRequireDefault(require("./user-is-comp-admin"));
var _userIsSystemAdmin = _interopRequireDefault(require("./user-is-system-admin"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// Competition Divisions
// This just lists the fields that a user can update - there may be other restrictions imposed.

function userCanUpdateDivisionField(fieldName, competitionID, user) {
  if ((0, _userIsCompAdmin.default)(competitionID, user) && ['isRestricted', 'restriction'].includes(fieldName)) return true;
  if ((0, _userIsSystemAdmin.default)(user) && ['isIncludedInEntryLimits', 'excludesEntriesInAnotherRace', 'isFreeToEnter', 'entryFee', 'lateFee', 'isRestricted', 'restriction', 'sessionsPerDay'].includes(fieldName)) return true;
  return false;
}