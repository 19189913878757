"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createButton;
const DEFAULTS = {
  type: 'secondary',
  size: 'md'
};
function createButton(displayName, render, defaults) {
  const result = props => {
    const outputProps = {
      ...DEFAULTS,
      ...defaults,
      ...props
    };
    const classes = ['btn'];
    classes.push('btn-' + outputProps.type + (outputProps.outline ? '-outline' : ''));
    if (outputProps.size !== 'md') {
      classes.push('btn-' + outputProps.size);
    }
    if (outputProps.block) {
      classes.push('btn-block');
    }
    if (outputProps.link) {
      classes.push('btn-link');
    }
    if (outputProps.active) {
      classes.push('active');
    }
    if (outputProps.disabled) {
      classes.push('disabled');
    }
    if (outputProps.leftJustify) {
      classes.push('text-xs-left');
    }
    return render(classes, outputProps);
  };
  result.displayName = displayName;
  return result;
}