'use strict';

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var React = require('react');
var PropTypes = require('prop-types');
var format = require('occasion');
var parse = require('dehumanize-date');
var LinkedValueUtils = require('./linked-value-utils');

var ReactDate = function (_React$Component) {
  (0, _inherits3.default)(ReactDate, _React$Component);

  function ReactDate(props) {
    (0, _classCallCheck3.default)(this, ReactDate);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ReactDate.__proto__ || (0, _getPrototypeOf2.default)(ReactDate)).call(this, props));

    _this._handleBlur = function (e) {
      _this.setState({ isEditing: false });
      if (_this.props.onBlur) {
        return _this.props.onBlur.call(_this, e);
      }
    };

    _this._handleChange = function (e) {
      var value = e.target.value;
      _this.setState({ textContent: value, isEditing: true });
      var returnValue;
      value = value ? _this._parse(value) : '';
      if (value !== null) {
        var invalid = _this.props.required && !value;
        if (!invalid && _this.state.invalid && _this.props.onValid) {
          _this.props.onValid.call(_this);
        }
        _this.setState({ invalid: invalid });

        var onChange = LinkedValueUtils.getOnChange(_this);
        if (onChange) {
          returnValue = onChange.call(_this, { target: { value: value } });
        }

        if (LinkedValueUtils.getValue(_this) == null) {
          _this.setState({ currentValue: value });
        } else {
          _this.setState({ currentValue: value });
        }
      } else {
        if (!_this.state.invalid && _this.props.onInvalid) {
          _this.props.onInvalid.call(_this);
        }
        _this.setState({ invalid: true });
      }
      return returnValue;
    };

    var defaultValue = props.defaultValue;
    _this.state = {
      currentValue: defaultValue != null ? defaultValue : null,
      isEditing: false,
      textContent: ''
    };
    return _this;
  }

  (0, _createClass3.default)(ReactDate, [{
    key: 'render',
    value: function render() {
      var props = {};
      for (var key in this.props) {
        if (key in this.props) {
          if (['value', 'defaultValue', 'valueLink', 'onChange', 'format', 'parse', 'onBlur', 'onInvalid', 'onValid', 'inputRef'].indexOf(key) === -1) {
            props[key] = this.props[key];
          }
        }
      }
      var value = LinkedValueUtils.getValue(this);
      value = value != null ? value : this.state.currentValue;
      if (this.state.isEditing && (null === this._parse(this.state.textContent) || value === this._parse(this.state.textContent))) {
        props.value = this.state.textContent;
      } else {
        props.value = this._format(value);
      }
      props.onChange = this._handleChange;
      props.onBlur = this._handleBlur;
      if (this.props.inputRef) {
        props.ref = this.props.inputRef;
      }
      return React.createElement('input', props);
    }
  }, {
    key: '_parse',
    value: function _parse(value) {
      if (typeof this.props.parse === 'string' && this.props.parse.toLowerCase() === 'us') {
        return parse(value, true);
      } else if (typeof this.props.parse === 'function') {
        return this.props.parse(value);
      } else {
        return parse(value);
      }
    }
  }, {
    key: '_format',
    value: function _format(value) {
      if (/^\d\d\d\d\-\d\d\-\d\d$/.test(value)) {
        if (typeof this.props.format === 'function') {
          return this.props.format(value);
        } else {
          return format(value, this.props.format);
        }
      } else {
        return value;
      }
    }
  }]);
  return ReactDate;
}(React.Component);

ReactDate.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInvalid: PropTypes.func,
  onValid: PropTypes.func,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  parse: PropTypes.oneOfType([PropTypes.oneOf(['us', 'US']), PropTypes.func])
};


module.exports = ReactDate;