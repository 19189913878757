"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getRacingText;
const occasion = require('occasion');
function getRacingText(application) {
  const racingText = [];
  // if application for only one day the division and special
  // will be picked up in name and title so don't need more info
  if (application.numberOfDays < 2) return [];

  // find out whether the application has same specials every day
  const specialsByDate = [];
  application.competitionDates.map(compDate => {
    const specials = application.applicationSpecials.filter(spec => spec.calendarDateID === compDate).map(spec => spec.specialLabel);
    specialsByDate.push({
      compDate,
      specials
    });
  });
  const firstDaySpecials = specialsByDate && specialsByDate[0].specials;
  const sameSpecials = specialsByDate.every(d => d.specials.every(spec => firstDaySpecials.includes(spec)) && firstDaySpecials.every(spec => d.specials.includes(spec)));
  if (application.isDouble) {
    racingText.push('First race ' + occasion(application.competitionDates[0], 'dddd') + ', Second race ' + occasion(application.competitionDates[1], 'dddd'));
    if (!sameSpecials) {
      specialsByDate.map(d => {
        if (d.specials.length) racingText.push(occasion(d.compDate, 'dddd') + ': ' + d.specials.join(' & '));
      });
    }
    return racingText;
  }

  // This  will pick up triple competitions which are normally
  // things like selection - in most cases this will mean the
  // same specials throughout picked up in titles,
  // but could conceivably be different

  if (application.applicationDivisions.length < 2) {
    if (!sameSpecials) {
      specialsByDate.map(d => {
        if (d.specials.length) racingText.push(occasion(d.compDate, 'dddd') + ': ' + d.specials.join(' & '));
      });
    }
    return racingText;
  }

  // multi division and at least two days with different things on different days

  // TODO filtering out specials is a hack - need to review specials

  specialsByDate.map(d => {
    const races = application.applicationRacing.filter(r => r.calendarDateID === d.compDate && r.competitionDivisionDefault.description !== 'Special').map(r => r.competitionDivisionDefault.description + (r.isRaceStartDay ? '' : 'contd'));
    const info = races.concat(d.specials);
    if (info.length) racingText.push(occasion(d.compDate, 'dddd') + ': ' + info.join(' & '));
  });
  return racingText;
}