"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSecondsToTime = addSecondsToTime;
exports.default = calculateStartList;
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// Add the elapsed seconds to the previous runtime to create an next runtime
function addSecondsToTime(time, secsElapsed) {
  const match = /^(\d\d?)\:(\d\d?)\:(\d\d?)$/.exec(time);
  if (!match) throw new Error('Invalid time string');
  const [, hourString, minuteString, secondString] = match;
  let hours = +hourString;
  let minutes = +minuteString;
  let seconds = +secondString;
  seconds += secsElapsed;
  if (seconds > 59) {
    minutes += Math.floor(seconds / 60);
    seconds = seconds % 60;
  }
  if (minutes > 59) {
    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;
  }
  // if more than 23 hours just skip into next day - not interested in recording day change
  if (hours > 23) {
    hours = hours % 24;
  }
  return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
}
class MutableTime {
  constructor(value) {
    _defineProperty(this, "_value", void 0);
    if (!/^(\d\d?)\:(\d\d?)\:(\d\d?)$/.test(value)) throw new Error('Invalid time string');
    this._value = value;
  }
  addSeconds(numberOfSeconds) {
    this._value = addSecondsToTime(this._value, numberOfSeconds);
  }
  setValue(value) {
    this._value = value;
  }
  getValue() {
    return this._value;
  }
}

/**
 * Take a list of entries, and get the start time for each entry
 *
 * @param runStartTime {MutableTime}
 * @param startListEntries {Array.<Entry>}
 * @param secsBetweenPaddlers {number} The number of seconds between each paddler
 * @returns {Array.<EntryPhase>}
 */

// TODO - get rid of any
function calculateEntryStartTimes(runStartTime, startListEntries, secsBetweenPaddlers) {
  const entryPhases = startListEntries.map((entry, i) => {
    if (i !== 0) {
      runStartTime.addSeconds(secsBetweenPaddlers);
    }
    return {
      ...entry,
      startTime: runStartTime.getValue()
    };
  });
  return entryPhases;
}

/**
 * Take the number of holding places, and return an array of "Holding Place" entries.
 *
 * @param runStartTime {MutableTime}
 * @param extraHoldingPlacesCount {Number}
 * @param secsBetweenPaddlers {number} The number of seconds between each paddler
 * @returns {Array.<EntryPhase>}
 */

function calculateHoldingPlacesStartTimes(runStartTime, entriesCount, numberOfHoldingPlaces, secsBetweenPaddlers) {
  const holdingPlaces = [];
  const extraHoldingPlacesCount = numberOfHoldingPlaces - entriesCount;
  for (let i = 0; i < extraHoldingPlacesCount; i++) {
    if (i !== 0 || entriesCount !== 0) {
      runStartTime.addSeconds(secsBetweenPaddlers);
    }
    const placeNumber = extraHoldingPlacesCount - i;
    holdingPlaces.push({
      id: placeNumber,
      name: 'tbd',
      place: placeNumber,
      startTime: runStartTime.getValue()
    });
  }
  return holdingPlaces;
}

/**
 * Take a list of all runs (i.e. phases of a given split race) and add the correct
 * start time to each run.
 *
 * @param startListRuns {Array.<Run>}
 * @returns Array.<{runs: Array.<Run>}>
 */

function calculateStartTimes(startListRuns) {
  // Set up the Run Times for each entry

  let runStartTime = new MutableTime('00:00:00');
  return startListRuns.map(startListRun => {
    if (startListRun.fixedStartTime) {
      runStartTime.setValue(startListRun.fixedStartTime);
    }

    // set up run times for each entry
    const entryPhases = calculateEntryStartTimes(runStartTime, startListRun.startListEntries, startListRun.secsBetweenPaddlers);

    // set up times for holding places
    // generate all the times but if only using holding places only add the first holding place to the output to save paper!
    const holdingPlaces = calculateHoldingPlacesStartTimes(runStartTime, startListRun.startListEntries.length, startListRun.numberOfHoldingPlaces, startListRun.secsBetweenPaddlers);
    if (startListRun.startListEntries.length || startListRun.numberOfHoldingPlaces) {
      runStartTime.addSeconds(startListRun.secsAfterPhase);
    }
    return {
      ...startListRun,
      startListEntries: entryPhases,
      holdingPlaces: holdingPlaces.length && startListRun.onlyUseHoldingPlaces ? [{
        ...holdingPlaces[0],
        name: 'places to be allocated. Event phase starts:'
      }] : holdingPlaces
    };
  });
}

/**
 * Take a list of all runs (i.e. phases of a given split race) and group them by
 * which splitRace they belong to.
 *
 * @param startListRuns {Array.<Run>}
 * @returns Array.<{runs: Array.<Run>}>
 */

function groupRunsBySplitRace(startListRuns) {
  const splitRacesByID = {};
  const splitRaces = [];
  for (const run of startListRuns) {
    const splitRaceID = run.raceID + '*' + run.runSplitNumber;
    if (!splitRacesByID[splitRaceID]) {
      splitRacesByID[splitRaceID] = {
        id: splitRaceID,
        race: run.race,
        runSplitNumber: run.runSplitNumber,
        startListEntriesByID: {},
        holdingPlacesByID: {},
        startListEntries: [],
        holdingPlaces: [],
        phaseRefs: []
      };
      splitRaces.push(splitRacesByID[splitRaceID]);
    }
    const splitRace = splitRacesByID[splitRaceID];
    run.startListEntries.forEach(startListEntry => {
      if (!splitRace.startListEntriesByID[startListEntry.id]) {
        splitRace.startListEntriesByID[startListEntry.id] = {
          ...startListEntry,
          startTimes: {}
        };
        splitRace.startListEntries.push(splitRace.startListEntriesByID[startListEntry.id]);
      }
      splitRace.startListEntriesByID[startListEntry.id].startTimes[run.phaseRef] = startListEntry.startTime;
      if (splitRace.phaseRefs.indexOf(run.phaseRef) === -1) {
        splitRace.phaseRefs.push(run.phaseRef);
      }
    });
    run.holdingPlaces.forEach(holdingPlace => {
      if (!splitRace.holdingPlacesByID[holdingPlace.id]) {
        splitRace.holdingPlacesByID[holdingPlace.id] = {
          ...holdingPlace,
          startTimes: {}
        };
        splitRace.holdingPlaces.push(splitRace.holdingPlacesByID[holdingPlace.id]);
      }
      splitRace.holdingPlacesByID[holdingPlace.id].startTimes[run.phaseRef] = holdingPlace.startTime;
      if (splitRace.phaseRefs.indexOf(run.phaseRef) === -1) {
        splitRace.phaseRefs.push(run.phaseRef);
      }
    });
  }
  return splitRaces;
}
function calculateStartList(startListRuns) {
  return groupRunsBySplitRace(calculateStartTimes(startListRuns));
}