"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getLimitsText;
const occasion = require('occasion');
function getLimitsText(application) {
  // any day with zero/no limit is assumed the same as other days
  // if they are the same as each other
  const nonZeroLimits = application.entryLimits.filter(limit => limit && limit > 0);
  // no limits set then tbc
  if (!nonZeroLimits.length) return 'Limit tbc';
  const firstLimit = nonZeroLimits[0];
  if (nonZeroLimits.every(limit => limit === firstLimit)) {
    return 'Limit ' + firstLimit;
  }
  const limitByDay = [];
  application.entryLimits.map((limit, i) => {
    if (limit && limit > 0) limitByDay.push(occasion(application.competitionDates[i], 'ddd') + ' ' + limit);
  });
  return limitByDay.join(' ');
}