"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = calendarOfficer;
function calendarOfficer(user) {
  // CLND = calendar officer, CHAR = chair, who also has access to stats and system admin
  if (user && ['ADMN', 'CLND', 'CHAR'].includes(user.roleRef)) return true;
  return false;
}