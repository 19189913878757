"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createClient;
function createClient(state, request) {
  const subscribed = [];
  const store = {
    getState() {
      return state;
    },
    subscribe(fn) {
      subscribed.push(fn);
      return () => {
        subscribed.splice(subscribed.indexOf(fn), 1);
      };
    },
    dispatch(action) {
      state = reducer(state, action);
      // fire off any network requests here
      subscribed.forEach(fn => fn());
      switch (action.type) {
        case 'LOGIN':
          request('POST', '/auth/login', {
            json: {
              email: state.email,
              returnPath: action.returnPath
            }
          }).getBody('utf8').then(str => {
            try {
              return JSON.parse(str);
            } catch (ex) {
              throw new Error(ex.message + '\n\n' + str + '\n\n');
            }
          }).then(response => {
            if (response.emailSent) {
              store.dispatch({
                type: 'EMAIL_CONFIRMATION_REQUIRED'
              });
            } else if ("production" !== 'production' && response.development) {
              // Simulate clicking on the link for development environment
              location.replace(response.path);
            }
          }, err => {
            store.dispatch({
              type: 'LOGIN_FAILED'
            });
            throw err;
          });
          break;
        case 'LOGOUT':
          request('POST', '/auth/logout').getBody('utf8').then(JSON.parse).then(() => location.reload(), () => location.reload());
          break;
      }
    }
  };
  return store;
}
function reducer(state, action) {
  switch (action.type) {
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.value,
        emailIsValid: isValidEmail(action.value)
      };
    case 'LOGIN':
      return {
        ...state,
        loggingIn: true
      };
    case 'EMAIL_CONFIRMATION_REQUIRED':
      return {
        ...state,
        loggingIn: false,
        emailConfirmationRequired: true
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        loggingIn: false,
        loginFailed: true
      };
    default:
      return state;
  }
}
function isValidEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email);
}