"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getQueryResponse;
function getQueryResponse(specialRef, queryResponses) {
  if (!queryResponses || !queryResponses.length) return '';
  const responseRecord = queryResponses.filter(response => response.specialRef === specialRef);
  if (!responseRecord || !responseRecord.length) return '';
  return responseRecord[0].response || '';
}