"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCalendarClass = getCalendarClass;
exports.getCalendarColor = getCalendarColor;
// Note: this makes it easier to manage colors for calendar and pdf printing in one place
// However, it does not cater for 'declined' applications which are handled in the code.

const colors = {
  LATE: {
    class: 'text-danger',
    color: '#d9534f'
  },
  APPY: {
    class: 'text-muted',
    color: '#818a91'
  },
  REQ: {
    class: 'text-warning',
    color: '#f0ad4e'
  },
  SUBM: {
    class: 'text-success',
    color: 'green'
  },
  COMP: {
    class: '',
    color: 'black'
  },
  DECL: {
    class: 'text-muted',
    color: '#818a91'
  }
};
function getCalendarClass(isLate, applicationStatusRef) {
  if (isLate && ['APPY', 'DECL'].includes(applicationStatusRef)) return colors.LATE.class;
  return colors[applicationStatusRef] && colors[applicationStatusRef].class;
}
function getCalendarColor(isLate, applicationStatusRef) {
  if (isLate && ['APPY', 'DECL'].includes(applicationStatusRef)) return colors.LATE.color;
  return colors[applicationStatusRef] && colors[applicationStatusRef].color || 'black';
}