"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = userCanViewStats;
// CHAR - chair also has access to calendar.
function userCanViewStats(user) {
  if (user && ['STAT', 'ADMN', 'CHAR', 'SAFE'].includes(user.roleRef)) return true;
  return false;
}