"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripePercentage = exports.stripeFixed = exports.stripeConnectPecentage = exports.payoutLatency = exports.payoutFrequency = exports.maxPaymentRefundDelay = exports.maxPaymentHold = exports.chargeLeadTime = exports.authLeadTime = void 0;
//******** STRIPE CHARGES **********

const stripeFixed = exports.stripeFixed = 20;
const stripePercentage = exports.stripePercentage = 1.4;
// export const stripeConnectFixed = 10;
const stripeConnectPecentage = exports.stripeConnectPecentage = 0.5;
// export const stripeActiveAccountCharge = 200;

//******** STRIPE BASED **********
// The following constants are defined based on Stripe rules

// This is the maximum length of time that funds can be held in Stripe
const stripeMaxHoldingTime = 90;

// Number of days stripe normally takes to clear a payment
// const stripeClearingTime = 7;

// This is the number of days for which an authorization remains valid on a customers card account
const stripeAuthHoldingTime = 7;

// ********* DEFINED ***************
// Based on stripe rules we define constants to decide when to charge, payout etc

// Where payment details are submitted on or after the authLeadTime
// we will take authorisation for payments.
// This prevents us from accepting entries one day only to struggle to take payment the next.
// As paddle up places are released from 28 days
// before competition it is useful to start taking authorisation
// at the same time
const authLeadTime = exports.authLeadTime = 28;

// Lead time (in days) before the competition that we charge the paddlers credit cards
// entries submitted on or after this time will be charged immediately.
// If competitions are rescheduled after this date then payout of those
// funds needs to occur within the stripeMaxHolding Time.
// We need to charge before pre-authorised charges expire (the +1 allows for time zone issues)
// 28-(7-1) = 22
const chargeLeadTime = exports.chargeLeadTime = authLeadTime - (stripeAuthHoldingTime - 1);

// Most payouts will be triggered by payoutLatency, but where comp has moved
// we need to look at maximum time we can hold payments.
// The -2 allows a bit of leeway on timezones etc
// This figure needs to be greater than the maxPaymentRefundDelay,
// so that refunds are issued first
// 90-2=88
const maxPaymentHold = exports.maxPaymentHold = stripeMaxHoldingTime - 2;

// Number of days after a competition that first payout take place.
// Under normal circumstances gives organisers a day
// or two to sort out any post-comp cancellations
// Needs to be at less than payouts triggered by maxPaymentHold
const payoutLatency = exports.payoutLatency = 6;

// Normally refunds are delayed until competition start to allow for
// re-instatements and then processed immediately.
// However, if a competition is moved after payments
// have been charged, then we need to issue the refunds before Stripe Payouts cut in.
// Further if a refund is made against an early charge then we still wait 2 days before
// processing the refund to allow for corrections.  So this number
// needs to be at least 3 days less than maxPaymentHold
// This is set on the basis that it is probably unreasonable to
// withold refunds more than 4 weeks after charging.
const maxPaymentRefundDelay = exports.maxPaymentRefundDelay = 28;

// once we have started payouts standard payouts
// we need to payout at regular intervals until we stop receiving funds
// Every three weeks should be fine
const payoutFrequency = exports.payoutFrequency = 21;