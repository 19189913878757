"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = competitionAdmin;
function competitionAdmin(competitionID, user) {
  if (user && user.roleRef === 'ADMN') return true;
  if (user && user.administratesCompetitions.indexOf(competitionID) !== -1) return true;
  return false;
}