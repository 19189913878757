"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatDateRange;
// formats an array of individual date strings into a
// displayable  date range eg 13/14 September
// assumes that the days include a date and that days are sorted in order

const occasion = require('occasion');
function formatDateRange(dates) {
  const lastDate = dates.length - 1;
  if (!dates || dates.length < 1) return null;
  if (dates.length === 1) return occasion(dates[0], 'D MMMM');
  if (occasion(dates[0], 'MMMM') === occasion(dates[lastDate], 'MMMM')) return occasion(dates[0], 'D/') + occasion(dates[lastDate], 'D MMMM');
  return occasion(dates[0], 'D MMMM/') + occasion(dates[lastDate], 'D MMMM');
}