"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateTransactionFees = calculateTransactionFees;
function calculateTransactionFees(subTotal) {
  // Note: 2.7% in calcs are based on UK fees of 1.4%
  // Stripe charges 1.4% + 20p for UK credit cards PLUS 0.5% for managed accounts
  // Because Stripe calculates based on total including charges it works out slightly over 1.9% + 20p
  // International cards are more expensive so if we get these then will need to reconsider
  if (!subTotal) return 0;
  return Math.ceil(subTotal * 0.027 + 58);
}