"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = groupStatsByCompDay;
function groupStatsByCompDay(entriesByDivisions) {
  const statsByCompDayID = {};
  const statsByCompDays = [];
  const divsInUseByID = {};
  const divsInUse = [];
  console.dir(entriesByDivisions);
  for (const div of entriesByDivisions) {
    const compDayID = div.competitionDayID;
    if (!statsByCompDayID[compDayID]) {
      statsByCompDayID[compDayID] = {
        ...div,
        competitionDayID: compDayID,
        entriesByDivisionID: {}
      };
      statsByCompDays.push(statsByCompDayID[compDayID]);
    }
    statsByCompDayID[compDayID].entriesByDivisionID[div.competitionDivisionDefaultID] = div.entries;
    const divID = div.competitionDivisionDefaultID;
    if (!divsInUseByID[divID]) {
      divsInUseByID[divID] = div;
      divsInUse.push(div);
    }
  }
  divsInUse.sort((a, b) => a.divLevel - b.divLevel);
  return [statsByCompDays, divsInUse];
}