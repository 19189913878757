"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const Heading = _styledComponents.default.h1`
  margin-bottom: 1rem;
  font-size: 52px;
  font-weight: 300;
  @media (max-width: 750px) {
    font-size: 7vw;
  }
`;
var _default = exports.default = Heading;